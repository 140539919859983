const linkCellRenderer = (props) => {
  const cellValue = props.value;
  return (
    cellValue && (
      <span>
        <a href={cellValue} target="_blank">
          <span className="ag-icon ag-icon-linked"></span>
        </a>
      </span>
    )
  );
};

export default linkCellRenderer;
