import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";

import {
  useGetRowsByPriceListIdQuery,
  useUpdatePriceListItemMutation,
} from "../../../services/api";

const round = (num) => {
  const factor = 10;
  return Math.round(num / factor) * factor - 1;
};

export default (props) => {
  const [
    updatePriceListItem,
    {
      data: updatePriceListItemData,
      error: updateRowError,
      isLoading: updateRowIsLoading,
    },
  ] = useUpdatePriceListItemMutation();

  const {
    register: registerPercentageMarkup,
    handleSubmit: handleSubmitPercentageMarkup,
  } = useForm();

  const {
    register: registerPercentageDiscount,
    handleSubmit: handleSubmitPercentageDiscount,
  } = useForm();

  const { handleSubmit: handleSubmitApplyRounding9 } = useForm();

  const activePriceListId = useSelector((state) => state.app.activePriceListId);

  // id: event.data.id,
  // field: event.column.colId,
  // value: event.newValue,
  // activePriceListId,

  const percentageDiscount = (data) => {
    const selectedRows = props.props.api.getSelectedRows();
    const payload = selectedRows.map((row) => ({
      id: row.id,
      field: "pricelist_price",
      value: row.unitPrice * (1 - data.percentageDiscount / 100),
      activePriceListId: Number(activePriceListId),
    }));

    updatePriceListItem(payload);
  };
  const percentageMarkup = (data) => {
    const selectedRows = props.props.api.getSelectedRows();
    const payload = selectedRows.map((row) => ({
      id: row.id,
      field: "pricelist_price",
      value: row.unitCost * (data.percentageMarkup / 100),
      activePriceListId: Number(activePriceListId),
    }));
    updatePriceListItem(payload);
  };
  const applyRounding9 = (data) => {
    const selectedRows = props.props.api.getSelectedRows();
    const payload = selectedRows.map((row) => ({
      id: row.id,
      field: "pricelist_price",
      value: round(row.pricelist_price),
      activePriceListId: Number(activePriceListId),
    }));
    updatePriceListItem(payload);
  };

  if (!activePriceListId) {
    return;
  }

  return (
    <div className="toolbarCard">
      <h3>Pricing</h3>
      <p>Pricing actions apply on selected rows</p>
      <form
        onSubmit={handleSubmitPercentageDiscount(percentageDiscount)}
        className="mb-10"
      >
        <label>
          Percentage discount
          <input
            {...registerPercentageDiscount("percentageDiscount", {
              required: true,
            })}
            placeholder="i.e: 20"
            className="ag-form-field"
          />
        </label>
        <input type="submit" />
      </form>
      <form
        onSubmit={handleSubmitPercentageMarkup(percentageMarkup)}
        className="mb-10"
      >
        <label>
          Percentage markup
          <input
            {...registerPercentageMarkup("percentageMarkup", {
              required: true,
            })}
            placeholder="i.e: 20 "
            className="ag-form-field"
          />
        </label>
        <input type="submit" />
      </form>
      <form onSubmit={handleSubmitApplyRounding9(applyRounding9)}>
        <label>
          Apply rounding (closest 9)
          <input type="submit" value="Apply rounding" />
        </label>
      </form>
    </div>
  );
};
