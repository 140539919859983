import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import {
  useCreatePriceListMutation,
  useGetMarketsQuery,
} from "../../../services/api";
// import { useGetMarketsQuery } from "../../../services/market";

export default (props) => {
  const { register, handleSubmit, reset } = useForm();

  const [
    createPriceList,
    {
      data: createPriceListData,
      error: createPriceListError,
      isSuccess: createPriceListSuccess,
      isLoading: createPriceListIsLoading,
    },
  ] = useCreatePriceListMutation();

  const {
    data: marketData,
    error: marketError,
    isLoading: marketIsLoading,
  } = useGetMarketsQuery();

  const onSubmit = (data) => {
    createPriceList(data);
  };

  useEffect(() => {
    if (createPriceListSuccess) {
      reset();
    }
  }, [createPriceListSuccess]);

  return (
    <div className="toolbarCard">
      <h3>Create new price list</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>
          Price list label
          <input
            {...register("priceListLabel", { required: true })}
            placeholder="i.e. Jaktdager"
            className="ag-form-field"
          />
        </label>
        <label>
          Start date
          <input
            {...register("priceListStartDate", { required: true })}
            placeholder="YYYY-MM-DD"
            className="ag-form-field"
          />
        </label>
        <label>
          Price list end date
          <input
            {...register("priceListEndDate", { required: true })}
            placeholder="YYYY-MM-DD"
            className="ag-form-field"
          />
        </label>
        {marketError ? (
          <p>Failed loading markets!</p>
        ) : marketIsLoading ? (
          <p>Loading...</p>
        ) : marketData ? (
          <label>
            Market
            <select {...register("selectMarket")} className="ag-form-field">
              {marketData.map((market) => (
                <option key={market.id} value={market.id}>
                  {market.label}
                </option>
              ))}
            </select>
          </label>
        ) : null}
        {/* <label>
            Price list type
            <select {...register("priceListType")} className="ag-form-field">
              <option value="" selected disabled hidden>
                Select type
              </option>
              <option>Cherry picking</option>
              <option>Rule based</option>
            </select>
          </label> */}
        {createPriceListError ? <p>Failed creating price list!</p> : null}
        {createPriceListIsLoading ? <p>Creating price list...</p> : null}
        {createPriceListData ? <p>Price list created!</p> : null}
        <input type="submit" />
      </form>
    </div>
  );
};
