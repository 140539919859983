const booleanCellRenderer = (props) => {
  const cellValue = props.value;

  const icon = cellValue ? "tick" : "cancel";
  const color = cellValue ? "green" : "red";

  return (
    <span>
      <span style={{ color }} className={"ag-icon ag-icon-" + icon}></span>
    </span>
  );
};

export default booleanCellRenderer;
