import { createSlice } from "@reduxjs/toolkit";

export const appSlice = createSlice({
  name: "app",
  initialState: {
    // add sidebar and column state here?
    activePriceListId: null,
    authToken: null,
  },
  reducers: {
    setActivePriceListId: (state, action) => {
      state.activePriceListId = action.payload;
    },
    setAuthToken: (state, action) => {
      state.authToken = action.payload;
    },
  },
});

export const { setActivePriceListId, setAuthToken } = appSlice.actions;

export default appSlice.reducer;
