import React from "react";
import ProductStatusCard from "./Cards/productStatusCard";
import FeedStatusCard from "./Cards/feedStatusCard";

export default (props) => {
  return (
    <div style={{ padding: "15px" }}>
      <h2>Actions</h2>
      {/* <InriverCard props={props} /> */}
      <FeedStatusCard props={props} />
      <ProductStatusCard props={props} />
    </div>
  );
};
