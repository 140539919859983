import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";

import { useGetPriceListsQuery } from "../../../services/api";
import { setActivePriceListId } from "../../../slices/appSlice";

export default (props) => {
  const { register, handleSubmit } = useForm();

  const { data, error, isLoading } = useGetPriceListsQuery();

  const dispatch = useDispatch();

  const activePriceListId = useSelector((state) => state.app.activePriceListId);

  // handle form submit
  const onSubmit = (data) => {
    dispatch(setActivePriceListId(data.selectPriceList));
  };

  return (
    <div className="toolbarCard">
      <h3>Select price list</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        {error ? (
          <p>Failed loading price lists!</p>
        ) : isLoading ? (
          <p>Loading...</p>
        ) : data ? (
          <select {...register("selectPriceList")} className="ag-form-field">
            <option key={99999} value={"null"}>
              None
            </option>
            {data.map((priceList) => (
              <option key={priceList.id} value={priceList.id}>
                {priceList.pricelist_label} ({priceList.market_label})
              </option>
            ))}
          </select>
        ) : null}
        <input type="submit" />
      </form>
    </div>
  );
};
