import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

export default (props) => {
  const { register, handleSubmit } = useForm();
  const [data, setData] = useState("");

  // handle form submit
  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <div className="toolbarCard">
      <h3>Remove selected products</h3>
      <form>
        <input type="submit" />
      </form>
    </div>
  );
};
