import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

/**
 * Authentication works like this:
 * 1. User clicks on login button
 * 2. User is redirected to google login page
 * 3. User logs in with google
 * 4. This hook gets the credential from google
 * 5. This hook sends the credential to the backend
 * 6. Backend validates the credential and returns a JWT
 * 7. JWT is stored in localStorage
 * 8. JWT is sent with every request to the backend via the Authorization header in RTK query
 * 9. Backend validates the JWT and returns the user and a new JWT
 * 10. User is stored in redux (and localStorage)
 * 11. User is redirected to the dashboard
 * 12. User is redirected to the login page if the JWT is invalid
 */

const useFetch = (url) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const handleGoogle = async (response) => {
    setLoading(true);
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({ credential: response.credential }),
    })
      .then((res) => {
        setLoading(false);

        return res.json();
      })
      .then((data) => {
        console.log("setting user", data);
        if (data?.user) {
          localStorage.setItem("user", JSON.stringify(data?.user));
          window.location.reload();
        }

        throw new Error(data?.message || data);
      })
      .catch((error) => {
        setError(error?.message);
      });
  };
  return { loading, error, handleGoogle };
};

export default useFetch;
