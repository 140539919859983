import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import dateFormat from "dateformat";
import { useGetPriceListByIdQuery } from "../../../services/api";
import { setActivePriceListId } from "../../../slices/appSlice";

const prettyDate = (timestamp) => {
  return dateFormat(new Date(timestamp), "dd-mm-yy");
};

export default (props) => {
  const { register, handleSubmit } = useForm();

  const activePriceListId = useSelector((state) => state.app.activePriceListId);

  const { data, error, isLoading } =
    useGetPriceListByIdQuery(activePriceListId);

  const dispatch = useDispatch();

  // the select box in "selectPriceListCard" is returning "null" (str) when the user selects "none"
  if (!activePriceListId || activePriceListId === "null") {
    return;
  }

  return (
    <div className="toolbarCard bg-green">
      <h3>Showing price list</h3>
      <p>
        <strong>{data[0].pricelist_label}</strong>
      </p>
      <p>Start date: {prettyDate(data[0].start_date)}</p>
      <p>End date: {prettyDate(data[0].end_date)}</p>
    </div>
  );
};
