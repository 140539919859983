/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { useForm } from "react-hook-form";
import { useUpdateFeedStatusMutation } from "../../../services/api";

export default (props) => {
  const { register, handleSubmit } = useForm();
  const [
    updateFeedStatus,
    {
      error: updateFeedStatusError,
      isSuccess: updateFeedStatusSuccess,
      isLoading: updateFeedStatusIsLoading,
    },
  ] = useUpdateFeedStatusMutation();


  const onSubmit = (data) => {
    const selectedRows = props.props.api.getSelectedRows();
    if (selectedRows.length === 0) {
      alert("No rows selected");
      return;
    }
    const payload = selectedRows
    .filter((el) => el.orgsHierarchy.length === 1)
    .map((row) => ({
      //todo add feed_id to row
      // id: row.feed_id,
      milrabSku: row.number,  
      activate: data.selectProductStatus === "active" ? true : false,
    }));
    updateFeedStatus(payload)
  };


  return (
    <div className="toolbarCard">
      <div className="toolbarCard-text">
        <h3>Feed status update</h3>
        <p>
          Set status "Vis på Web" active or inactive in Feed
        </p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>
          Set status
          <select
            {...register("selectProductStatus")}
            className="ag-form-field"
          >
            <option key="active" value="active">
              Active
            </option>
            <option key="deactive" value="deactive">
              Inactive
            </option>
          </select>
        </label>
        <input type="submit" />
      </form>
      {updateFeedStatusError ? <p>Failed updating status!</p> : null}
      {updateFeedStatusIsLoading ? <p>Updating status...</p> : null}
      {updateFeedStatusSuccess ? <p>Status updated!</p> : null}
    </div>
  );
};
