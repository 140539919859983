// turn off serializability checks for this to work
// OR register cellrenderer as component
// https://ag-grid.com/react-data-grid/components/#registering-custom-components

const initialColumnDefs = [
  {
    headerName: "BC Master",
    children: [
      {
        field: "number",
        headerCheckboxSelection: true,
        checkboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        width: 220,
        filter: "agTextColumnFilter",
        pinned: "left",
      },
      {
        field: "displayName",
        headerName: "Name",
        width: 260,
        filter: "agTextColumnFilter",
        pinned: "left",
      },
      {
        field: "status",
        headerName: "Status",
        width: 120,
        filter: "agSetColumnFilter",
      },
      {
        field: "category_name",
        headerName: "Category",
        width: 120,
        filter: "agSetColumnFilter",
      },
      {
        field: "vendor_item_no",
        headerName: "Vendor item no",
        width: 120,
        filter: "agTextColumnFilter",
      },
      {
        field: "ean",
        headerName: "EAN",
        width: 120,
        filter: "agTextColumnFilter",
      },
      {
        field: "inventory",
        headerName: "Total inventory",
        width: 150,
        filter: "agNumberColumnFilter",
      },
      {
        field: "quantityForSale",
        headerName: "Tilgjengelig for salg BC",
        width: 190,
        filter: "agNumberColumnFilter",
      },
      {
        field: "avgProductMarginLast30d",
        headerName: "Average margin Last 30d",
        width: 200,
        filter: "agNumberColumnFilter",
      },
      {
        field: "avgLineMarginPercentage",
        headerName: "Average margin %",
        width: 165,
        filter: "agNumberColumnFilter",
      },
      { field: "unitPrice", width: 120, filter: "agNumberColumnFilter" },
      { field: "unitCost", width: 120, filter: "agNumberColumnFilter" },
    ],
  },
  {
    headerName: "Attributes",
    children: [
      {
        field: "attr_brand",
        width: 120,
        filter: "agTextColumnFilter",
        headerName: "Brand",
      },
      {
        field: "attr_gender",
        width: 120,
        filter: "agSetColumnFilter",
        headerName: "Gender",
      },
      {
        field: "attr_color",
        width: 120,
        filter: "agSetColumnFilter",
        headerName: "Color",
      },
      {
        field: "attr_vendor_color",
        width: 130,
        filter: "agTextColumnFilter",
        headerName: "Vendor color",
      },
    ],
  },
  {
    headerName: "Active",
    children: [
      {
        field: "bc_web_item",
        headerName: "BC",
        width: 80,
        cellRenderer: "booleanCellRenderer",
      },
      {
        field: "pim_product_status",
        headerName: "PIM Base Status",
        width: 150,
        cellRenderer: "booleanCellRenderer",
        tooltipField: 'Base product status is Beriket'
      },
      {
        field: "pim_variant_status",
        headerName: "PIM Variant Status",
        width: 170,
        cellRenderer: "booleanCellRenderer",
        tooltipField: 'Variant status is Beriket'
      },
      {
        field: "pim_active_status",
        headerName: "PIM Active Status",
        width: 170,
        cellRenderer: "booleanCellRenderer",
        tooltipField: 'Variant "vis på web" is active'
      },
      {
        field: "NO_URL",
        headerName: "Milrab.no",
        width: 100,
        cellRenderer: "booleanCellRenderer",
      },
      {
        field: "SE_URL",
        headerName: "Milrab.se",
        width: 100,
        cellRenderer: "booleanCellRenderer",
      },
    ],
  },
  {
    headerName: "Incoming purchases",
    children: [
      {
        field: "incoming_quantity",
        headerName: "Qty",
        width: 120,
        filter: "agNumberColumnFilter",
      },
      {
        field: "incoming_date",
        headerName: "Date",
        width: 120,
        filter: "agTextColumnFilter",
        cellRenderer: (data) => {
          return data.value ? new Date(data.value).toLocaleDateString() : "";
        },
      },
    ],
  },
  {
    headerName: "Sales",
    children: [
      {
        field: "DTE7",
        headerName: "DTE7",
        width: 150,
        filter: "agNumberColumnFilter",
      },
      {
        field: "DTE30",
        headerName: "DTE30",
        width: 150,
        filter: "agNumberColumnFilter",
      },
      {
        field: "sales_today",
        headerName: "today",
        width: 120,
        filter: "agNumberColumnFilter",
      },
      {
        field: "sales_yesterday",
        headerName: "yesterday",
        width: 120,
        filter: "agNumberColumnFilter",
      },
      {
        field: "sales_7d",
        headerName: "7d",
        width: 120,
        filter: "agNumberColumnFilter",
      },
      {
        field: "sales_30d",
        headerName: "30d",
        width: 120,
        filter: "agNumberColumnFilter",
      },
      {
        field: "sales_60d",
        headerName: "60d",
        width: 120,
        filter: "agNumberColumnFilter",
      },
      {
        field: "sales_90d",
        headerName: "90d",
        width: 120,
        filter: "agNumberColumnFilter",
      },
    ],
  },
  {
    headerName: "Returns",
    children: [
      {
        field: "returns_7d",
        headerName: "7d",
        width: 120,
        filter: "agNumberColumnFilter",
      },
      {
        field: "returns_30d",
        headerName: "30d",
        width: 120,
        filter: "agNumberColumnFilter",
      },
      {
        field: "returns_60d",
        headerName: "60d",
        width: 120,
        filter: "agNumberColumnFilter",
      },
    ],
  },
  {
    headerName: "Optimizely",
    children: [
      { field: "brand", width: 120, filter: "agTextColumnFilter" },
      { field: "color", width: 120, filter: "agSetColumnFilter" },
      { field: "link", width: 120, cellRenderer: "linkCellRenderer" },
      { field: "price", width: 120 },
    ],
  },
  {
    headerName: "Reprice",
    children: [
      {
        field: "reprice_lowest",
        headerName: "Lowest",
        width: 120,
        filter: "agNumberColumnFilter",
      },
      {
        field: "reprice_diff",
        headerName: "Diff",
        width: 120,
        filter: "agNumberColumnFilter",
      },
      {
        field: "reprice_competitors_name",
        headerName: "Competitor",
        width: 120,
        filter: "agNumberColumnFilter",
      },
    ],
  },
  {
    headerName: "Prisjakt",
    children: [
      {
        field: "pj_popularity_total",
        headerName: "Pop total",
        width: 120,
        filter: "agNumberColumnFilter",
      },
      {
        field: "pj_popularity_in_category",
        headerName: "Pop in cat",
        width: 120,
        filter: "agNumberColumnFilter",
      },
      {
        field: "pj_popularity_trend",
        headerName: "Pop trend",
        width: 120,
        filter: "agNumberColumnFilter",
      },
    ],
  },
  {
    headerName: "Kurans",
    children: [
      {
        field: "kurans_antall_varianter",
        headerName: "Cnt var",
        width: 120,
        filter: "agNumberColumnFilter",
      },
      {
        field: "kurans_antall_varianter_pa_lager",
        headerName: "Cnt var instock",
        width: 140,
        filter: "agNumberColumnFilter",
      },
      {
        field: "kurans_antall_kurante_varianter_pa_lager",
        headerName: "Cnt kurante var instock",
        width: 120,
        filter: "agNumberColumnFilter",
      },
      {
        field: "kurans_sum",
        headerName: "Kurans sum",
        width: 120,
        filter: "agNumberColumnFilter",
      },
    ],
  },
  {
    headerName: "Campaign history",
    children: [
      {
        field: "campaignhistory_pricelistdescription",
        headerName: "Price list",
        width: 120,
        filter: "agSetColumnFilter",
      },
      { field: "campaignhistory_unitprice", headerName: "Price", width: 80 },
      {
        field: "campaignhistory_daydiff",
        headerName: "Days ago",
        width: 100,
        filter: "agNumberColumnFilter",
      },
    ],
  },

  // { field: "type" },
  // { field: "itemCategoryId" },
  // { field: "blocked" },
  // { field: "gtin" },
  // { field: "priceIncludesTax" },
  // { field: "taxGroupId" },
  // { field: "taxGroupCode" },
  // { field: "baseUnitOfMeasureId" },
  // { field: "baseUnitOfMeasureCode" },
  // { field: "generalProductPostingGroupId" },
  // { field: "generalProductPostingGroupCode" },
  // { field: "inventoryPostingGroupId" },
  // { field: "inventoryPostingGroupCode" },
];

export default initialColumnDefs;
