import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import CreatePriceListCard from "./Cards/createPriceListCard";
import AddProductsPriceListCard from "./Cards/addProductsPriceListCard";
import RemoveProductsPriceListCard from "./Cards/removeProductsPriceListCard";
import SelectPriceListCard from "./Cards/selectPriceListCard";
import ActivePriceListCard from "./Cards/activePriceListCard";
import PricingActionsCard from "./Cards/pricingActionsCard";

export default (props) => {
  return (
    <div style={{ padding: "15px" }}>
      <h2>Price lists</h2>
      <SelectPriceListCard props={props} />
      <ActivePriceListCard props={props} />
      <PricingActionsCard props={props} />
      <AddProductsPriceListCard props={props} />
      {/* <RemoveProductsPriceListCard props={props} /> */}
      <CreatePriceListCard props={props} />
    </div>
  );
};
