/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { useUpdateProductStatusMutation } from "../../../services/api";
import { useForm } from "react-hook-form";

export default (props) => {
  const { register, handleSubmit } = useForm();

  const onSubmit = (data) => {
    const selectedRows = props.props.api.getSelectedRows();
    const payload = selectedRows
      .filter((el) => el.orgsHierarchy.length === 1)
      .map((row) => ({
        status: data.selectProductStatus,
        bc_item_id: row.id,
        bc_item: row.number,
        bc_variant: null,
      }));

    console.log(payload);

    updateProductStatus(payload);
  };

  const [
    updateProductStatus,
    {
      error: updateProductStatusError,
      isSuccess: updateProductStatusSuccess,
      isLoading: updateProductStatusIsLoading,
    },
  ] = useUpdateProductStatusMutation();

  return (
    <div className="toolbarCard">
      <div>
        <h3>Product status</h3>
        <p>
          1 = Utgått, skal ikke suppleres <br />
        </p>
        2 = Foreløpig utsolgt (sjekkes en gang i måneden) <br />
        <p>
        </p>
        <p>
          STARS = For produkter i STARS <br />
        </p>
        <p>
          B2B = Defendo SKU <br />
        </p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>
          Set status
          <select
            {...register("selectProductStatus")}
            className="ag-form-field"
          >
            <option key="1" value="1">
              1
            </option>
            <option key="2" value="2">
              2
            </option>
            <option key="STARS" value="STARS">
              STARS
            </option>
            <option key="B2B" value="B2B">
              B2B
            </option>
          </select>
        </label>
        <input type="submit" />
      </form>
      {updateProductStatusError ? <p>Failed updating status!</p> : null}
      {updateProductStatusIsLoading ? <p>Updating status...</p> : null}
      {updateProductStatusSuccess ? <p>Status updated!</p> : null}
    </div>
  );
};
