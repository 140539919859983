import PriceListToolPanel from "../ToolPanels/PriceLists/priceListToolPanel";
import ActionsToolPanel from "../ToolPanels/Actions/actionsToolPanel";
import PresetsToolPanel from "../ToolPanels/Presets/PresetsToolPanel";

const sidebar = {
  toolPanels: [
    {
      id: "columns",
      labelDefault: "Columns",
      labelKey: "columns",
      iconKey: "columns",
      toolPanel: "agColumnsToolPanel",
    },
    {
      id: "filters",
      labelDefault: "Filters",
      labelKey: "filters",
      iconKey: "filter",
      toolPanel: "agFiltersToolPanel",
    },
    {
      id: "priceLists",
      labelDefault: "Price lists",
      labelKey: "priceLists",
      iconKey: "custom-stats",
      toolPanel: PriceListToolPanel,
    },
    {
      id: "actions",
      labelDefault: "Actions",
      labelKey: "actions",
      iconKey: "custom-stats",
      toolPanel: ActionsToolPanel,
    },
    {
      id: "presets",
      labelDefault: "Presets",
      labelKey: "presets",
      iconKey: "custom-stats",
      toolPanel: PresetsToolPanel,
    }
  ],
  defaultToolPanel: "priceLists",
};

export default sidebar;
