import React, { useCallback, useEffect, useState } from "react";
import { useDeletePMSFilterMutation, useGetPMSFilterQuery, useSetPMSFilterMutation } from "../../services/api";



const PresetsToolPanel = (props) => {
  const { data, refetch } = useGetPMSFilterQuery([]);
  const [filterList, setFilterList] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [filterName, setFilterName] = useState("");
  const [showOverwriteMessage, setShowOverwriteMessage] = useState(false);
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);

  useEffect(() => {
    if (data && data.length > 0) {
      setFilterList(data);
      setSelectedFilter(data[0].name);
    }
  }, [data]);

  const [
    setPMSFilter,
    {
      error: setPMSFilterError,
      isSuccess: setPMSFilterSuccess,
      isLoading: setPMSFilterIsLoading,
    },
  ] = useSetPMSFilterMutation();
  const [
    deletePMSFilter,
    {
      error: deletePMSFilterError,
      isSuccess: deletePMSFilterSuccess,
      isLoading: deletePMSFilterIsLoading,
    },
  ] = useDeletePMSFilterMutation();


  const handleSelectChange = async (e) => {
    setSelectedFilter(e.currentTarget.value);
  };


  const handleFilterNameChange = (event) => {
    setFilterName(event.target.value);
  };


  const handleSaveFilter = async (event) => {
    event.preventDefault();
    const newdata = await refetch();
    setFilterList(newdata.data);
    if (newdata.data.some(filter => filter.name.toLowerCase() === filterName.toLowerCase().trim())) {
      return setShowOverwriteMessage(true);
    }
    saveFilterModel();
  }
  const handleOverwrite = (overwrite) => {
    if (overwrite) {
      saveFilterModel(overwrite);
      setShowOverwriteMessage(false);
      return
    }
    setShowOverwriteMessage(false);
  }

  const handleDeleteFilter = async () => {
    setShowDeleteMessage(true);
  }


  const saveFilterModel = useCallback(async (overwrite = false) => {
    const newFilterModel = props.api.getFilterModel();
    if (Object.keys(newFilterModel).length < 1 || !filterName) {
      alert("Filter is empty or filter name is missing")
      return;
    }
    const columnFilterState = props.api.getColumnState()
    const { email } = JSON.parse(localStorage.getItem("user"));
    await setPMSFilter({
      payload: { filter: newFilterModel, column_filter_state:columnFilterState, created_by: email, name: filterName.trim(), overwrite },
    });
    const newFilterList = await refetch()
    setFilterList(newFilterList.data)
    setFilterName("");
  }, [props.api, setPMSFilter, filterName, refetch]);

  const loadFilter = useCallback(({selectedFilter}) => {
    const loadSelectedFilter = filterList.find(filter => {
      return filter.name === selectedFilter.trim()
    });
    props.api.setFilterModel(loadSelectedFilter.filter);
    props.api.applyColumnState({state:loadSelectedFilter.column_filter_state})
  }, [props.api, filterList]);

  const deleteFilter = useCallback(async ({ response, selectedFilter }) => {
    if (!response) {
      return
    }
    const filter = filterList.find(filter => filter.name.toLowerCase() === selectedFilter.toLowerCase().trim());
    await deletePMSFilter({ payload: { filter } })
    setShowDeleteMessage(false);
    const newData = await refetch()
    setFilterList(newData.data)
  }, [deletePMSFilter, refetch, filterList]);

  const resetFilter = () => {
    props.api.setFilterModel({})
    props.api.resetColumnState();
  }


  return (
    <div style={{ padding: "15px" }}>
      <h2>Presets</h2>
      <p>Presets are a way to save and load filter settings.</p>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
        <form style={{ display: 'flex', gap: '0.5rem', flexDirection: 'column' }}>
          <input
            type="text"
            value={filterName}
            onChange={handleFilterNameChange}
            placeholder="Enter filter name"
          />
          <button onClick={(e) => handleSaveFilter(e)}>Save/Update filter</button>
        </form>
        {showOverwriteMessage && (
          <div className="modal">
            <p>Filter with this name already exists. Do you want to overwrite it?</p>
            <button onClick={() => handleOverwrite(true)}>Yes</button>
            <button onClick={() => handleOverwrite(false)}>No</button>
          </div>
        )}
        {setPMSFilterError ? <p>Failed saving filter!</p> : null}
        {setPMSFilterIsLoading ? <p>Saving filter...</p> : null}
        {setPMSFilterSuccess ? <p>Filter Saved!</p> : null}
        <select disabled={!filterList} onChange={(e) => handleSelectChange(e)}>
          {filterList && filterList.map((filter) => (
            <option key={filter.id} value={filter.name} >{filter.name}</option>
          ))}
        </select>
        <button disabled={filterList?.length < 1 || !filterList} onClick={() => loadFilter({selectedFilter})}>Load filter</button>
        <button onClick={resetFilter}>Reset filter</button>
        <button disabled={filterList?.length < 1 || !filterList} onClick={handleDeleteFilter}>Delete Filter</button>
        {showDeleteMessage && (
          <div className="modal">
            <p>Are you sure you want to delete "{selectedFilter}"? </p>
            <button onClick={() => deleteFilter({ response: true, selectedFilter })}>Yes</button>
            <button onClick={() => deleteFilter({ reponse: false, selectedFilter })}>No</button>
          </div>
        )}
        {deletePMSFilterError ? <p>Failed deleting filter!</p> : null}
        {deletePMSFilterIsLoading ? <p>Deleting filter...</p> : null}
        {deletePMSFilterSuccess ? <p>Filter Deleted!</p> : null}

      </div>

    </div>
  );
};

export default PresetsToolPanel;