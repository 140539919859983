import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";

import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component

import { useSelector, useDispatch } from "react-redux";

import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

import initialColumnDefs from "../constants/initialColumnDefs";
import priceListColumnDefs from "../constants/priceListColumnDefs";
import initialSidebar from "../constants/initialSidebar";

import booleanCellRenderer from "../cellRenderers/booleanCellRenderer";
import linkCellRenderer from "../cellRenderers/linkCellRenderer";

import {
  selectColumns,
  addColumn,
  addColumnGroup,
  // updateColumns,
  // removeColumn,
  removeColumnGroup,
} from "../slices/columnSlice";

import {
  useGetRowsByPriceListIdQuery,
  useUpdatePriceListItemMutation,
  useGetVariantRowsByPriceListIdQuery,
} from "../services/api";

const App = () => {
  const [
    updatePriceListItem,
    {
      data: updatePriceListItemData,
      error: updatePriceListItemError,
      isLoading: updatePriceListItemIsLoading,
    },
  ] = useUpdatePriceListItemMutation();

  const gridRef = useRef();

  const [gridApi, setGridApi] = useState(null);

  const dispatch = useDispatch();

  const columns = useSelector(selectColumns);

  const activePriceListId = useSelector((state) => state.app.activePriceListId);

  const {
    data: rowsData,
    error: rowsError,
    isLoading: rowsIsLoading,
  } = useGetRowsByPriceListIdQuery(activePriceListId);


  const [activeRows, setActiveRows] = useState([]);

  const {
    data: rowsVariantData,
    error: rowsVariantError,
    isLoading: rowsVariantIsLoading,
  } = useGetVariantRowsByPriceListIdQuery(activePriceListId);


  useEffect(() => {
    if (rowsData && rowsData.length) {
      if (rowsVariantData && rowsVariantData.length) {
        setActiveRows([...rowsVariantData, ...rowsData]);
      } else {
        setActiveRows([...rowsData]);
      }
    }
  }, [rowsData, rowsVariantData]);

  const onGridReady = (params) => {
    dispatch(addColumn(initialColumnDefs));
    setGridApi(params.api);

    // params.api.setGetContextMenuItems(() => {
    //   return ["foobar"];
    // });
  };


  useEffect(() => {
    // really need to get my shit together here...
    const clearFilters = () => {
      gridApi.setFilterModel(null);
    };

    const deselectAllRows = () => {
      gridApi.deselectAll();
    };
    if (activePriceListId !== "null" && activePriceListId !== null) {
      dispatch(addColumnGroup(priceListColumnDefs));
      clearFilters();
      deselectAllRows();
    } else {
      dispatch(removeColumnGroup("Price list"));
    }
  }, [activePriceListId, dispatch, gridApi]);

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => {
    return {
      editable: false,
      sortable: true,
      filter: true,
      resizable: true,
      floatingFilter: true,
      enableRowGroup: true,
    };
  }, []);

  const [components] = useState({
    booleanCellRenderer,
    linkCellRenderer,
  });

  const icons = useMemo(() => {
    return {
      "custom-stats": '<span class="ag-icon ag-icon-custom-stats"></span>',
    };
  }, []);

  const sideBar = useMemo(() => {
    return initialSidebar;
  }, []);

  const getRowId = useCallback((params) => {
    return params.data.id;
  }, []);


  const onCellEditRequest = (event) => {
    // console.log("onCellEditRequest", event);

    const change = {
      id: event.data.id,
      field: event.column.colId,
      value: event.newValue,
      activePriceListId,
    };

    updatePriceListItem(change);
  };

  // @todo: Excessive re-rendering - mitigate via whyDidYouRender
  // console.log("App is rendering");

  // const [newRowsData, setNewRowsData] = useState([]);
  // useEffect(() => {
  //   if (rowsData && rowsData.length > 0) {
  //     let tempNewData = rowsData.map((row) => {
  //       return {
  //         ...row,
  //         orgsHierarchy: [row.number],
  //       };
  //     });
  //     setNewRowsData(tempNewData);
  //   }
  // }, [rowsData]);


  return (
    <div className="ag-theme-alpine hundredPercent">
      <AgGridReact
        groupDefaultExpanded={0}
        ref={gridRef} // Ref for accessing Grid's API
        rowData={activeRows} // Row Data for Rows
        onGridReady={onGridReady}
        columnDefs={columns} // Column Defs for Columns
        defaultColDef={defaultColDef} // Default Column Properties
        animateRows={false} // Optional - set to 'true' to have rows animate when sorted
        rowSelection="multiple" // Options - allows click selection of rows
        icons={icons}
        sideBar={sideBar}
        getRowId={getRowId} // We are using RTK which is immutable data, so we need to set a unique ID for each row
        readOnlyEdit={true} // Prevent ag-grid from editing data, we will handle this via RTK
        onCellEditRequest={onCellEditRequest}
        components={components}
        enableRangeSelection={true}
        getDataPath={(data) => data.orgsHierarchy}
        treeData={true}
      // rowData={newRowsData} // Row Data for Rows
      // rowData={rowsData} // Row Data for Rows
      // getDataPath={(data) => console.log(data)}
      // getDataPath={(data) => data.path}
      // groupDefaultExpanded={-1}
      // getContextMenuItems={(p) => console.log(p)}
      // onCellClicked={cellClickedListener} // Optional - registering for Grid Event
      />
    </div>
  );
};

export default App;
