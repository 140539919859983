import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  useGetPriceListsQuery,
  useCreatePriceListItemsMutation,
} from "../../../services/api";

export default (props) => {
  const { register, handleSubmit } = useForm();

  const [
    createPriceListItems,
    {
      data: createPriceListItemsData,
      error: createPriceListItemsError,
      isSuccess: createPriceListItemsSuccess,
      isLoading: createPriceListItemsIsLoading,
    },
  ] = useCreatePriceListItemsMutation();

  const {
    data: getPriceListData,
    error: getPriceListError,
    isLoading: getPriceListIsLoading,
  } = useGetPriceListsQuery();

  // handle form submit
  const onSubmit = (data) => {
    const selectedRows = props.props.api.getSelectedRows();
    const payload = selectedRows.map((row) => ({
      pricelist_id: data.selectPriceList,
      bc_item_id: row.id,
      bc_item: row.number,
      bc_variant: null,
    }));
    createPriceListItems(payload);
  };

  const activePriceListId = useSelector((state) => state.app.activePriceListId);

  if (activePriceListId) {
    return;
  }

  return (
    <div className="toolbarCard">
      <h3>Add selected products</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        {getPriceListError ? (
          <p>Failed loading price lists!</p>
        ) : getPriceListIsLoading ? (
          <p>Loading...</p>
        ) : getPriceListData ? (
          <select {...register("selectPriceList")} className="ag-form-field">
            {getPriceListData.map((priceList) => (
              <option key={priceList.id} value={priceList.id}>
                {priceList.pricelist_label} ({priceList.market_label})
              </option>
            ))}
          </select>
        ) : null}
        {createPriceListItemsError ? <p>Failed adding products!</p> : null}
        {createPriceListItemsIsLoading ? <p>Adding products...</p> : null}
        {createPriceListItemsSuccess ? <p>Products added!</p> : null}
        <input type="submit" />
      </form>
    </div>
  );
};
