const priceListColumnDefs = [
  {
    headerName: "Price list",
    children: [
      {
        field: "pricelist_price",
        headerName: "Price",
        pinned: "right",
        editable: true,
        width: 100,
        cellStyle: {
          backgroundColor: "lemonchiffon",
        },
      },
      {
        field: "pricelist_discount_percent",
        headerName: "Discount %",
        pinned: "right",
        width: 100,
        valueGetter:
          "(data.unitPrice - data.pricelist_price) / data.unitPrice * 100",
        valueFormatter: "Math.round(value, 2)",
      },
      {
        field: "pricelist_margin_percent",
        headerName: "Margin %",
        pinned: "right",
        width: 100,
        valueGetter:
          "(data.pricelist_price - data.unitCost) / data.pricelist_price * 100",
        valueFormatter: "Math.round(value, 2)",
      },
      {
        field: "pricelist_margin_abs",
        headerName: "Margin abs.",
        pinned: "right",
        width: 100,
        valueGetter: "data.pricelist_price - data.unitCost",
        valueFormatter: "Math.round(value, 2)",
      },
    ],
  },
];

export default priceListColumnDefs;
