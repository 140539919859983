import { configureStore } from "@reduxjs/toolkit";
// import { setupListeners } from "@reduxjs/toolkit/query";

import { api } from "./services/api";
import appSlice from "./slices/appSlice";
import columnSlice from "./slices/columnSlice";

import { isRejectedWithValue, isFulfilled } from "@reduxjs/toolkit";

const rtkQueryErrorLogger = (api) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    // todo: use Toast to show error
    if (action.payload?.status === 401) {
      console.log("401");
      // api.util.resetApiState();
      localStorage.removeItem("user");
      window.location.reload();
    }
    // toast.warn({ title: "Async error!", message: action.error.data.message });
  }

  return next(action);
};

const rtkQueryFulfilledLogger = (api) => (next) => (action) => {
  // todo: use Toast to show success on mutations
  if (isFulfilled(action)) {
    // Check for renewed token and set localStorage
    const newToken =
      action.meta.baseQueryMeta.response.headers.get("x-refresh-token");

    if (newToken) {
      // console.log("newToken", newToken);
      const lsUser = localStorage.getItem("user");
      const jsonUser = JSON.parse(lsUser);
      jsonUser.token = newToken;
      localStorage.setItem("user", JSON.stringify(jsonUser));
    }
  }

  return next(action);
};

export const store = configureStore({
  reducer: {
    app: appSlice,
    columns: columnSlice,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      api.middleware,
      rtkQueryErrorLogger,
      rtkQueryFulfilledLogger
    ),
});
